<template>

  <div v-if="organizations.length > 0">

    <token-list-add-new
      :is-add-new-token-sidebar-active.sync="isAddNewTokenSidebarActive"
      :organization="organizationFilter"
      :renew-options="renewOptions"
      :operation-types-options="allOperationTypes"
      @refetch-data="refetchData"
    />



    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->


          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewTokenSidebarActive = true"
              >
                <span class="text-nowrap">Add Token</span>
              </b-button>
            </div>
          </b-col>
          <b-col md="3">
            
                
                <v-select
                  v-model="organizationFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="organizations"
                  :reduce="option => option.id"
                  label="value"
                  class="report-filter-select"
                  placeholder="Select Organization"
                >
                  <template #selected-option="{ value }">
                    <span class="text-truncate overflow-hidden">
                      {{ value }}
                    </span>
                  </template>
                </v-select>
         
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTokenListTable"
        class="position-relative"
        :items="fetchTokens"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

 
        <!-- Column: Token -->
        <template #cell(token)="data">
          <b-input-group>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-form-input @click="$event.target.setSelectionRange(0, data.item.token.length)" v-model="data.item.token"></b-form-input>
          </b-input-group>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-tokens-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-tokens-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTokens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useTokensList from './useTokensList'
import tokenStoreModule from '../tokenStoreModule'
import TokenListAddNew from './TokenListAddNew.vue'
import axios from '@axios'
export default {
  components: {
    TokenListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    vSelect,
  },
  data() {
    return {
      organizations: []
    }
  },
  mounted(){
      axios.get(`organizations`, {params: {limit: 1000, selectedFields: "id name"}}).then((response) => {
        this.organizations = response.data.results.map((x) => {
          return {
            id: x.id,
            value: x.name,
          };
        });
        this.organizationFilter = this.organizations[0].id
      })
  },
  setup() {
    const TOKEN_APP_STORE_MODULE_NAME = 'app-token'

    // Register module
    if (!store.hasModule(TOKEN_APP_STORE_MODULE_NAME)) store.registerModule(TOKEN_APP_STORE_MODULE_NAME, tokenStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOKEN_APP_STORE_MODULE_NAME)) store.unregisterModule(TOKEN_APP_STORE_MODULE_NAME)
    })

    
    const isAddNewTokenSidebarActive = ref(false)

    const renewOptions = [
      { text: 'None', value: 'NONE' },
      { text: 'Once a week', value: 'WEEKLY' },
      { text: 'Once a month', value: 'MONTHLY' },
    ]

    const allOperationTypes = [
      { label: 'All', value: 'ALL' },
      { label: 'Image search', value: 'IMAGE_SEARCH' },
      { label: 'Image editor', value: 'IMAGE_EDITOR' },
      { label: 'Contilt scheme', value: 'CONTILT_SCHEME' },
    ]
    const {
      fetchTokens,
      tableColumns,
      perPage,
      currentPage,
      totalTokens,
      dataMeta,
      perPageOptions,
      searchQuery,
      organizationFilter,
      sortBy,
      isSortDirDesc,
      refTokenListTable,
      refetchData,
    } = useTokensList()

    return {

      // Sidebar
      isAddNewTokenSidebarActive,

      fetchTokens,
      tableColumns,
      perPage,
      currentPage,
      totalTokens,
      dataMeta,
      perPageOptions,
      searchQuery,
      organizationFilter,
      sortBy,
      isSortDirDesc,
      refTokenListTable,
      refetchData,

      renewOptions,
      allOperationTypes
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
