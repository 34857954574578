import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTokens(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tokens', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchToken(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tokens/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteToken(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/tokens/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addToken(ctx, tokenData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tokens/generateAPItoken', tokenData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    editToken(ctx, {id, tokenData}) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/tokens/${id}`, tokenData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    generateRegisterToken(ctx, tokenData){
      return new Promise((resolve, reject) => {
        axios
          .post(`/auth/generate-register-token`, tokenData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
