import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTokensList() {
  // Use toast
  const toast = useToast()

  const refTokenListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'description', sortable: true },
    { key: 'token', sortable: false },
    { key: 'credits', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalTokens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('');
  const organizationFilter = ref(null);
  
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTokenListTable.value ? refTokenListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTokens.value,
    }
  })

  const refetchData = () => {
    refTokenListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, organizationFilter], () => {
    refetchData()
  })

  const fetchTokens = (ctx, callback) => {
    store
      .dispatch('app-token/fetchTokens', {
        limit: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        namespace: "APItoken",
        organization: organizationFilter.value,
        sortDesc: isSortDirDesc.value
      })
      .then(response => {
        const { results, totalResults } = response.data

        callback(results)
        totalTokens.value = totalResults
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tokens list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

 

  return {
    fetchTokens,
    tableColumns,
    perPage,
    currentPage,
    totalTokens,
    dataMeta,
    perPageOptions,
    searchQuery,
    organizationFilter,
    sortBy,
    isSortDirDesc,
    refTokenListTable,

    refetchData
  }
}
